<template>
	<div class="appMain">
		<web-header></web-header>
		<div class="main">
			<router-title :menuList="menuList[0]" :list="menuList[1]" :list1="menuList[2]" />
		</div>
		<div class="content">
			<div class="contentLeft" >
				<div class="leftTop">{{data.courseName}}</div>
				<div class="leftBottom">
					<video-player class="video-player vjs-custom-skin" :class="studyTime === 100? '': 'videoJdu'"
						ref="videoPlayer" :playsinline="true" :options="playerOptions" @play="onPlayerPlay($event)" @ended="onPlayerEnded($event)"
						@timeupdate="onPlayerTimeupdate($event)" @pause="onPlayerPause($event)" @ready="playerReadied" >
					</video-player>
					<p v-if="isTrySeeTimeShow">自动从上次{{tryseeTime}}秒开始播放...</p>
					<p v-else-if="coursedata.isPay === 0">当前视频可试看{{tryseeTime}}秒</p>
				</div>
			</div>
			<div class="contentOneRight" :style="{height:videoHeight+'px'}">
				<div class="rightOne">
					<div @click="toShiKan(it)" class="cardCls" v-for="(it,i) in sectionList" :key="i">
						<div class="item_l">
							<span class="cicle"></span>
							<div class="sectionName" :title="it.name" :style="it.id === sectionId ? 'color:#39AEFF' : ''">
								{{i+1}}.{{it.name}}
							</div>
						</div>
						<div class="item_r">
							<!-- <div class="shikan">{{coursedata.isPay===0?'试看':'播放'}}</div> -->
							<div class="time">
								<span>
									<img src="../../assets/index/button.png" alt="">
								</span>
								<span
									:style="it.id === sectionId ? 'color:#39AEFF' : ''">{{timeData(it.duration)}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	import {
		MessageBox
	} from 'element-ui';
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页", "培训项目", "课程详情"],
				id: '',
				sectionId: '',
				sectionList: [],
				videoUrl: '',
				// 封面地址
				coverPicture: '',
				//播放器配置
				playerOptions: {},
				tipsStatus: true,
				testStatus: 1,
				status: '',
				hangTime: 0, //防挂机时间
				data: {},
				// 可以试看的时间
				tryseeTime: '',
				// 已经播放的时间
				seeafterTime: '',
				//上次播放时间提示显示
				isTrySeeTimeShow: false,
				// 视频防止用户挂机的操作
				isonhook: false,
				// 窗口发生变化的时候
				flag: true,
				countPaly: 0,
				antiHookTimer: null,
				// 定义一个值，如果鼠标移动了，就进行变化
				isTouchmove: false,
				// 当前章节的学习时间
				studyTime: 0,
				timer: '',
				// 最开始鼠标的位置
				mouseX: 0,
				// 最新的鼠标位置
				newMouseX: 0,
				count: 0,
				x: 0,
				y: 0,
				videoHeight:0,//视频高度 为了右侧章节栏和视频高度一致
				updateVideoTime:false,// 是否已上传过视频时长
			}
		}, //
		created() {
			window.addEventListener('beforeunload', e => this.pageDestroy())
			//禁止鼠标右键菜单
			document.oncontextmenu = function(e) {
				return false;
			};
			this.id = this.$route.query.id
			this.sectionId = this.$route.query.sectionId - 0
			// 是否购买
			this.status = this.$route.query.status
			
			this.data = this.$route.query.data;
			// 课程数据
			this.coursedata = this.$route.query.listCourse
			// 观看时间
			this.tryseeTime = this.$route.query.time
			this.orderId = this.$route.query.orderId //订单ID
			// console.log(this.tryseeTime,'1112');
			this.hangTime = parseInt(this.$route.query.hangTime)
			
			//获取章节信息
			this.$http.post('getCourseSectionById', {
				id: this.id
			}).then(res => {
				if (res.code === 200) {
					this.sectionList = res.data
					this.sectionList.forEach(it => {
						if (this.sectionId === it.id) {
							// this.videoUrl = it.videoUrl
							// this.coverPicture = it.coverPicture
							// 当前课程的学习进度 

							// this.studyTime = it.studyTime
							this.toShiKan(it)
						}
					})
					// console.log(this.sectionList, 'this.sectionList');
					this.videoEvt()
				} else {
					this.$message.error(res.message)
				}
			})
			// console.log(this.studyTime, 'studyTime');
		},
		computed: {
			player() {
				return this.$refs.videoPlayer.player;
			},
		},
		mounted() {
			// 监听鼠标
			document.onmousemove = (event) => {
				let x1 = event.clientX
				let y1 = event.clientY
				if (this.x !== x1 || this.y !== y1) {
					this.count = 0
				}
				this.x = x1
				this.y = y1
			}
			// 监听键盘
			document.onkeydown = () => {
				this.count = 0
			}
			// 监听Scroll
			document.onscroll = () => {
				this.count = 0
			}
		},
		// 最后在beforeDestroy()生命周期内清除定时器：
		beforeDestroy() {
			this.onPlayerPause(this.player)
			this.clearTimer()
		},
		destroyed() {
			window.removeEventListener('beforeunload', e => this.pageDestroy())
		},
		methods: {
			// 页面卸载执行操作
			pageDestroy() {
				this.player.pause()
			},
			//点击章节切换视频
			toShiKan(it) {
				this.updateVideoTime = false
				this.sectionId = it.id
				this.studyTime = it.studyTime
				this.$http.post('studyCourseApi', {
					courseId: this.id,
					sectionId: it.id
				}).then(res => {
					if (res.code === 200) {
						if (!res.data.id) {
							this.status = 0
							this.tryseeTime = res.data.tryTime
						} else {
							this.status = 1
							this.tryseeTime = res.data.watchTheTime
							this.$forceUpdate()
							this.tipsStatus = true
						}
					}
				})
				this.videoUrl = it.videoUrl
				this.coverPicture = it.coverPicture
				// 如果已购买并且上次观看时间大于0 则上次播放时间提示显示
				if (this.coursedata.isPay === 1 && this.tryseeTime > 0) {
					this.isTrySeeTimeShow = true;
				} else {
					this.isTrySeeTimeShow = false;
				}
				this.videoEvt()
			},
			//视频配置
			videoEvt() {
				this.playerOptions = {
					autoplay: false, //如果true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 导致视频一结束就重新开始。
					preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: 'zh-CN',
					aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: "", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
						src: this.videoUrl //url地址
					}],
					poster: this.coverPicture, //你的封面地址
					// width: document.documentElement.clientWidth, //播放器宽度
					notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true,
						durationDisplay: true,
						remainingTimeDisplay: false,
						fullscreenToggle: true //全屏按钮
					}
				}
			},
			onPlayerTimeupdate(e) {
				// clearInterval(this.timer);
				let currentTime = Number(e.cache_.currentTime.toFixed(2)); //当前播放时长
				this.seeafterTime = parseInt(currentTime);
				if (this.status == 0) {
					
					if (parseInt(currentTime) >= this.$route.query.time - 0 && this.testStatus === 1) {
						this.testStatus = 2
						this.$message.warning('试看已结束,请购买再观看！')
						localStorage.setItem('cousreId', this.id)
						this.$router.push({
							path: '/trainingProgram/paymentOrder',
							query: {
								index: 3,
								type: 2,
								data: e
							}
						})
						// setTimeout(() => {
						// 	this.$router.push()
						// }, 1500)
					}
				}
			},
			//设置恢复进度
			playerReadied(e) {
				if(e.el_){
					this.videoHeight = e.el_.clientHeight;
					
				}
				if (this.status == 0) {
					e.currentTime(0)
				} else {
					setTimeout(() => {
						e.currentTime(this.tryseeTime)
					}, 1000)
				}
			},
			_updateVideoTime(num){
				this.$http.post('updateVideoTime',{sectionId:this.sectionId,timeNumber:num}).then(res=>{
					if(res.code === 200){
						this.updateVideoTime = true
					}
				})
			},
			//视频开始播放
			onPlayerPlay(e) {
				// 上传视频时长
				if(!this.updateVideoTime){
					this._updateVideoTime(parseInt(e.cache_.duration))
				}
				if (this.studyTime < 100 && this.hangTime !== 0) {
					this.setTimer()
				}
				this.isTouchmove = false
				// console.log(this.isTouchmove, '视频播放');
				this.tipsStatus = false
				// 开始播放时 把上次观看时间提示关闭
				this.isTrySeeTimeShow = false;
			},
			//视频暂停
			onPlayerPause(e) {
				if (this.studyTime < 100 && this.hangTime !== 0) {
					this.clearTimer()
				}
				if (this.studyTime < 100 && this.status == 1) {
					this.$http.post('studyProgressApi', {
						courseId: this.id,
						sectionId: this.sectionId,
						orderId: this.orderId || '',
						time: parseInt(e.cache_.currentTime)
					}).then(res=>{
						if(res && res.message) return this.$message.success(res.message)
					}).catch(err=>{
						if(err && err.message) return this.$message.error(err.message)
					})
					
				}

			},
			// 视频播放完成
			onPlayerEnded(e){
				if(this.status == 1){
					this.studyTime = 100;
				}
			},
			// 时间转换
			timeData(time) {
				var hh;
				var mm;
				var ss;
				//传入的时间为空或小于0
				if (time == null || time < 0) {
					return;
				}
				//得到小时
				hh = time / 3600 | 0;
				time = parseInt(time) - hh * 3600;
				if (parseInt(hh) < 10) {
					hh = "0" + hh;
				}
				//得到分
				mm = time / 60 | 0;
				//得到秒
				ss = parseInt(time) - mm * 60;
				if (parseInt(mm) < 10) {
					mm = "0" + mm;
				}
				if (ss < 10) {
					ss = "0" + ss;
				}
				// if (hh == '00') return mm + ":" + ss
				// if (hh == '00' && mm == '00') return ss
				return hh + ":" + mm + ":" + ss;
			},
			clearTimer() {
				clearInterval(window.myTimer)
				window.myTimer = null
			},
			setTimer() {
				//   this.count = this.hangTime
				if (!window.myTimer) {
					window.myTimer = window.setInterval(this.cookieTimeout, 1000)
				}
			},
			cookieTimeout() {
				// 判断用户多少分钟无操作就暂停视频
				let outTime = this.hangTime
				this.count++
				if (this.count === outTime) {
					this.$alert('请确认您还是学习中......', '警告', {
						confirmButtonText: '确定',
					});
					this.player.pause()
				}
			},
			// 获取课程信息
			_getCourseInfoById(){
				this.$http.post('getCourseInfoById', {
				id: this.id
			}).then(res=>{
				if(res.code == 200){
					return res.data.studyCchedule === 100 && res.data.isExam 
				}else{
					return false
				}
			})
			}
		},
		watch: {
			// 'mouseX':(val)=>{
			// 	console.log(val);
			// 	// if(this.mouseX)
			// }
		}
	}
</script>

<style lang="less" scoped>
	.appMain {
		background: #eee;
	}

	.main {
		width: 1200px;
		margin: auto;
	}

	.content {
		display: flex;
		justify-content: space-around;
		margin-bottom: 30px;

		.contentLeft {
			flex: 2;

			.leftTop {
				width: 70%;
				background: #FFFFFF;
				font-size: 24px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
				text-align: center;
				line-height: 90px;
			}

			.leftBottom {
				// width: 1300px;
				// position: relative;

				p {
					position: absolute;
					color: white;
					bottom: 30px;
					left: 30px;
				}
			}
		}

		p {
			margin-bottom: 10px;

			span {
				color: #39AEFF;
				font-weight: bold;
			}
		}

		.contentOneRight {
			width: 520px;
			margin-left: 10px;
			// width: 30%;
			background: #FFFFFF;
			box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
			padding: 32px;
			overflow-y: scroll;

			.rightOne {
				border-left: 1px solid#39AEFF;
			}

			.cardCls {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #333333;
				margin-bottom: 42px;
				position: relative;
				padding-left: 15px;
				// line-height: .3;
				cursor: pointer;

				.item_l {
					display: flex;
					align-items: center;

					.sectionName {
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						width: 260px;
					}

					.cicle {
						display: block;
						width: 5px;
						height: 5px;
						background: #39AEFF;
						border-radius: 50%;
						position: absolute;
						left: -3px;
						top: 0;
					}
				}

				.item_r {
					display: flex;
					align-items: center;

					.shikan {
						// position: absolute;
						// right: 100px;
						// top: -8px;
						width: 47px;
						height: 21px;
						border: 1px solid #FF0000;
						border-radius: 11px;
						text-align: center;
						line-height: 21px;
						font-size: 12px;
						font-family: PingFang SC;
						font-weight: bold;
						color: #39AEFF;
						cursor: pointer;
					}

					.time {
						// position: absolute;
						// right: 0;
						// top: -6px;
						display: flex;
						align-items: center;
						line-height: 1;
						cursor: pointer;

						span {
							display: block;

							img {
								width: 16px;
								height: 16px;
								margin-right: 10px;
							}
						}
					}
				}

			}
		}
	}

	.videoJdu {
		::v-deep .vjs-progress-control {
			display: none;
		}
	}
</style>
